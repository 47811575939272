
.wse-dropdown{
    box-sizing: border-box;
    display: inline-block;
    position: relative;
  }
  .wse-dropdown-toggle{
    cursor: pointer;
    white-space: nowrap;
  }
  .wse-dropdown-toggle.has-drop::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .wse-dropdown-menu{
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
    left: 0;
    right: auto;
  }
  .wse-dropdown-menu.w-right{
    left: auto;
    right: 0;
  }
  .wse-dropdown-menu.show {
    display: block;
  }
  .wse-dropdown-item{
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    cursor: pointer;
  }
  .wse-dropdown-item:focus, .wse-dropdown-item:hover {
    color: #212529;
    background-color: #f8f9fa;
  }


