* {
  font-family: "Poppins", sans-serif;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.blurry-bg {
  backdrop-filter: blur(3px);
}
.w-text-primary {
  color: #006849;
}

.general_link {
  color: #264dfc;
  font-weight: bold;
}
.general_link:hover {
  color: #173ff5;
}

.w-bg-primary {
  background-color: #006849;
}

.w-bg-primary:hover {
  background-color: #03ce91;
}

.rotate-hor {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.doct-interface {
  backdrop-filter: blur(4px);
}

.interface-left {
  transform: rotate(-22deg);
}

.doct-interface {
  border: 1px solid #04f771;
  border-radius: 15px;
  transition: all 0.2s;
}

.image-wrapper:hover .interface-left {
  transform: translateX(-25px) translateY(-25px) rotate(-22deg) translateZ(0px);
}

.image-wrapper:hover .interface-middle {
  transform: translateX(0px) translateY(-25px) translateZ(0px);
}
.image-wrapper:hover .interface-right {
  transform: translateX(25px) translateY(-25px) rotate(22deg) translateZ(0px);
}
.bg-muted {
  background-color: hsl(0 0% 23.4%);
}
.text-muted-foreground {
  color: hsl(0 0% 85%);
}

.tr-scale {
  transform: scale(1);
}
.td-abs {
  position: absolute;
  top: 0;
  right: 0;
}
.text-archivix-800 {
  color: rgb(77 150 25 / 1);
}

.bg-archivix-200 {
  background-color: rgb(231 249 218 / 1);
}
.bg-archivix {
  background-color: rgb(162 231 113 / 1);
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-primary:hover {
  background-color: rgba(162, 231, 113, 0.9);
}
.border-border {
  border-color: hsl(0 0% 27.9%);
}

.cavaet-font {
  font-weight: 500;
  font-style: normal;
  font-family: "Alex Brush", cursive;
}
.border-field-card-border {
  border-color: hsl(95.08 71.08% 67.45%);
}
.bg-field-card\/80 {
  background-color: hsl(95 74% 90% / 0.8);
}

.rect-fld-signer {
  position: absolute;
  user-select: auto;
  width: 200px;
  height: 60px;
  display: inline-block;
  top: 0px;
  left: 0px;
  cursor: move;
  transform: translate(199px, 1152px);
  max-width: 9.0072e15px;
  max-height: 9.0072e15px;
  box-sizing: border-box;
  z-index: 10;
}

#cursor-circle {
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  /*transform: translate(-50%, -50%);*/
  z-index: 1000;
}
.bg-primary {
  background-color: hsl(95.08 71.08% 67.45%);
}
.text-primary-foreground {
  color: hsl(95.08 71.08% 10%);
}

.sign-cav canvas{
  width: 100%;
  height: 100%;
}
.react-pdf__Page .react-pdf__Page__canvas{
  width: 100% !important;
}
.text-muted-foreground {
  color: hsl(215.4 16.3% 46.9%);
}
.bg-muted {
  background-color: hsl(210 40% 96.1%);
}
.dropdown-toggle.not-drop::after{
  display: none !important;
}

.img-slider-img-wrapper {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}
.img-slider-img-v1 {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
.img-slider-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}
.img-slider-btn {
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
}
.img-slider-btn:hover,
.img-slider-btn:focus-visible {
  background-color: rgba(0, 0, 0, 0.2);
}
.img-slider-btn:hover > *,
.img-slider-btn:focus-visible > * {
  animation: squish 200ms ease-in-out;
}
.img-slider-btn > * {
  stroke: white;
  fill: black;
  width: 2rem;
  height: 2rem;
}

@keyframes squish {
  50% {
    scale: 1.4 0.6;
  }
}
.img-slider-dot-btn {
  all: unset;
  display: block;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  transition: scale 200ms ease-in-out;
}
.img-slider-dot-btn > * {
  stroke: white;
  fill: black;
  height: 100%;
  width: 100%;
}

.img-slider-dot-btn:hover,
.img-slider-dot-btn:focus-visible {
  scale: 1.2;
}

.img-slider-dot-btn:focus-visible,
.img-slider-btn:focus-visible {
  outline: auto;
}
.wise-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.wise-grid::after{
  content: "";
  flex: auto;
}

.wise-spec-grid{
  display: grid; /* 1 */
  grid-template-columns: repeat(auto-fill, 100px); /* 2 */
  grid-gap: 1rem; /* 3 */
  justify-content: space-between; /* 4 */
}

.sett-menus li{
  margin-right: 1rem;
}
.sett-menus li a{
  display: block;
  padding: 6px 8px;
  background-color: #E4E6E3;
  
  color: #000;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.sett-menus li a span{
  position: relative;
  z-index: 1;
}
.sett-menus li a:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #92E554;
  transition: width 1s ease-in-out;
  display: none;
  z-index: 0;
}
.sett-menus li a[data-active=true] span.m-title,
.sett-menus li a:hover span.m-title{  
  color: #fff;
}
.sett-menus li a[data-active=true]::before,
.sett-menus li a:hover::before{
  animation: menuanim 150ms ;
  display: block;
}

@keyframes menuanim {
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}
.dropdown-toggle.not-dropdown::after {
  display: none;
}
.text-archivix-700 {
  --tw-text-opacity: 1;
  color: rgb(102 198 34 / var(--tw-text-opacity));
}
.hover\:text-archivix-600:hover {
  --tw-text-opacity: 1;
  color: rgb(131 223 65 / var(--tw-text-opacity));
}
.maxw35ch {
  max-width: 388px;
}
.wise-fire-dlg h2.swal2-title{
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #999;
  padding-bottom: 12px;
}
.wise-fire-dlg .swal2-html-container{
  text-align: left;
  margin-left: 1em;
}
.wise-fire-dlg .swal2-actions{
  width: 100%;
  justify-content: end;
  padding-right: .7em;
}
.wise-fire-dlg .swal2-actions button{
  outline: none;
  padding: .4em .8em;
}
.wise-fire-dlg .swal2-actions button.swal2-confirm{
  background-color: #027e6f;
}
.wise-fire-dlg .swal2-actions button.swal2-cancel{
  background-color: rgb(0 0 0 / 0.1);
  color: #000;
}

.wise-fire-dlg .small-title{
  font-size: 14px;
  font-weight: 900;
}
.wise-fire-dlg .sign-disclosure{
  color: #027e6f;
}

.wise-fire-dlg .small-body{
  display: inline-block;
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}
.w-text-primary{
  color: #027e6f;
}
.bg-border {
  background-color: hsl(0 0% 27.9%);
}

.bg-wwidget{
  background-color: #fff;
}

.w-pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}





